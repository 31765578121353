<template>
	<div>
		<div class="js-copy js-content width_1200">
			<img
				style="width: 100%"
				src="https://zy.metaera.media/assets/images/aboutus/download_bg.png"
				alt=""
			/>
		</div>
		<div
			style="position: absolute; top: 70%; width: 100%; text-align: center"
			@click="download()"
		>
			<img
				style="width: 80%"
				src="https://zy.metaera.media/assets/images/aboutus/download_btn.png"
				alt=""
			/>
		</div>
		<div
			style="
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #000000ca;
			"
			v-if="olead == true"
		>
			<img
				style="width: 100%"
				src="https://zy.metaera.media/assets/images/aboutus/download_alert.png"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return { osafai: false, olead: false };
	},
	created() {},
	methods: {
		download() {
			let ua = navigator.userAgent.toLowerCase();
			//ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.olead = true;
			}
			if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || isiOS) {
				//判断是ios还是android
				window.location.href =
					"https://apps.apple.com/cn/app/meta-era/id6450677755"; // 跳AppStore下载地址
			} else if (/(Android)/i.test(navigator.userAgent)) {
				window.location.href = "https://zy.metaera.media/MetaEra100.apk"; //打开调整的地址
				// const fileName = "file/MetaEra100.apk"; //打开调整的地址
				// const fileUrl = "/path/to/" + fileName; // 文件的URL地址
				// const link = document.createElement("a");
				// link.href = fileName;
				// link.setAttribute("download", fileName);
				// link.click();
			} else {
				window.location.href = "https://zy.metaera.media/MetaEra100.apk"; //打开调整的地址
				// const fileName = "file/MetaEra100.apk"; //打开调整的地址
				// const fileUrl = "/path/to/" + fileName; // 文件的URL地址
				// const link = document.createElement("a");
				// link.href = fileName;
				// link.setAttribute("download", fileName);
				// link.click();
			}
		},
	},
};
</script>

<style scoped>
.js-copy {
	background: #fff;
	margin: 0 auto;
}
@media screen and (max-width: 950px) {
	.js-copy {
		background: #fff;
		margin: 0 auto;
		margin-top: calc(100vw * -80 / 375);
	}
}
</style>
